.conference_hall{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 70%;
    z-index: 6;
    &__back{
        width: 825px;
        height: 370px;
        position: absolute;
        margin-left: 10%;
        margin-top: -11%;
        background: url(../images/conference_hall/conference_back.png);
        background-size: cover;
        backdrop-filter: blur(140px);

        
        border-radius: 24.5221px;

        &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            box-shadow: 0 0 50px #ff4a8f76;
        
            border-radius: 24.5221px;
        }

        h1{
            margin-left: 7%;
            margin-top: 6%;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 39px;
        }
        .top-of-front-text{
            position: absolute;
            margin-left: 48%;
            margin-top: -6%;
            font-weight: 300;
            font-size: 20px;
            line-height: 115%;
        }
        ul{
            margin-top: 2em;
            margin-left: 2%;
            li{
                list-style-type: none;
                font-size: 18px;
                background: url(../images/conference_hall/marker.png) no-repeat left center;
                margin-bottom: 21px;
                p{
                    margin-left: 2.5em;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }


    }
    &__front{
        position: relative;
        height: 295px;
        width: 639px;
        z-index: 999;
        margin-left: 45%;
        margin-bottom: 5%;

        background: url(../images/conference_hall/conference_hall_blur.png) 100% 100% no-repeat;
        background-size: cover;
        transition: background-image 1s;
        &:hover{
            background: url(../images/conference_hall/conference_hall.png) 100% 100% no-repeat;
            background-size: cover;
        }
    }

}