.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  &__image {
    mix-blend-mode: screen;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  &__item {
    position: relative;
    margin-right: 40px;
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;

    &--language {
      font-weight: normal;

      &-active {
        font-weight: 800;
      }

      &-rus {
        margin-right: 22px;
      }
    }

    &--beta {
      font-size: 12px;
      line-height: 15px;
      color: #c3f438;
      position: absolute;
      top: -15px;
      right: -10px;
    }

    &--wallet {
      padding: 11px 16px;
      border: 6px solid #FF66A1;
      box-shadow: 0 0 5px 1px #FF66A1;
      border-radius: 25px;
      &:hover{
        border: 6px solid #ff498e;
        box-shadow: 0 0 5px 1px #ff498e;
      }
    }

    &--eth {
      padding: 11px 16px;
      border: 4px solid #ff498e;
      border-radius: 25px;
      margin-left: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 17px rgba(255, 102, 161, 0.85);

      & img {
        background: #fff;
        max-height: 25px;
        padding: 2px 6px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
}
