
.faq{
    max-width: 1310px;
    width: 83%;
    display: flex;
    justify-content: space-around;
    h1{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 45px;
        width: 21%;
    }
    .faq-slider{
        display: flex;
        flex-direction: column;
        width: 36%;
        .faq-items{
            position: absolute;
            border-top: 1px solid rgba(255,255,255,0.1);
            padding: 0;
            margin: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s;
            li{
                list-style-type: none;
                border-bottom: 1px solid rgba(255,255,255,0.1);
                .faq-question{
                    cursor: pointer;
                    display: flex;
                    div{
                        img{
                            height: 30px;
                            width: 30px;
                            margin-top: 25px;
                            &.answer__image{
                                display: none;
                            }
                            &.question__image{
                                display: block;
                            }
                        }
    
                        &.answer_image_visible{
                            img{
                                height: 30px;
                                width: 30px;
                                margin-top: 25px;
                                &.answer__image{
                                    display: block;
                                }
                                &.question__image{
                                    display: none;
                                }
                            }
                        }
                    }
                    p{
                        margin-left: 20px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
    
                        &.color-gray{
                            color: #FFFFFF;
                            opacity: 0.3;
                        }
                    }
                }
                .faq-answer{
                    font-family: 'Nunito', sans-serif;
                    display: none;
                    margin-left: 43px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            &--active{
                position: relative;
                visibility: visible;
                opacity: 1;
            }
        }
        .faq-buttons{
            display: flex;
            justify-content: space-between;
            margin-top: 5%;
            button{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                background: none;
                box-shadow: none;
                border: none;
                cursor: pointer;
            }
            .faq-more-button{
                color: #FF67A2;
            }
            .faq-prev-button{
                opacity: 0;
                cursor: default;
                color: #FF67A2;
            }
            .faq-next-button{
                display: none;
                color: #FF67A2;
            }
        }
    }
}