.introduce {
  margin-top: 10%;
  margin-left: 110px;

  &__subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 115%;
  }

  &__title {
    font-family: 'Montserrat';
    width: 40%;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    margin-top: 2%;
  }

  &__button {
    padding: 16px 70px;
    background: #ff66a1;
    border-radius: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border: none;
    color: #fff;
    margin-top: 50px;
    cursor: pointer;
    box-shadow: 0px 0px 17px rgba(255, 102, 161, 0.85);
    &:hover{
      background: #FF498E;
      box-shadow: 0px 0px 25px rgba(255, 102, 161, 0.4);
    }
  }
}
