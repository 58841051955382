.app-container {
  width: 100%;
  display: flex;
  background: #e999c7;
  color: #1e1c4c;
  padding: 50px;
  .features .features-slider{
    &__row{
      width: 100%;
      margin-left: -35px;
      margin-top: 55px;
    }
  }
}
