@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import "header";
@import "introduce";
@import "metaverse";
@import "features";
@import "for-what";
@import "app";
@import "profit";
@import "dashboard";
@import "conference_hall";
@import "faq";

* {
  box-sizing: border-box;
}

body {
  background: #272552;
  font-family: Montserrat, sans-serif;
  padding: 0;
  color: #fff;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-screen {
  background-image: url("../images/first-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;

  &__space {
    background: #15133d;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0%) 0%, #15133d 100%);
    width: 100%;
    height: 120px;
  }
}

.main-buildings-text {
  position: absolute;
  z-index: 6;
  transform: rotate(-90deg);
  margin-bottom: 60em;
  margin-left: -23vw;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 275;
  font-size: 66px;
  line-height: 80px;
  letter-spacing: 0.48em;

  color: #ffffff;

  opacity: 0.2;
}

.colliseum {
  margin-top: -5%;
  height: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/colliseum_back.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: 0 0 200px 250px #181541;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 5;
  h1 {
    width: 50%;
    font-style: italic;
    font-weight: 300;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    &.mobile {
      display: none;
    }
  }
  div {
    width: 30%;
    margin: 10% 15%;
    align-self: flex-start;
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 39px;
      width: 100%;
      text-align: left;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      width: 89%;
    }
  }
  img {
    display: none;
  }
}

.hallverfull {
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url(../images/hallverfull_back.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 6;
}

.footer {
  padding-top: 30px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 100px 100px #272552;
  .logo {
    margin-top: 148px;
  }
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.copyright {
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
}

.icon {
  margin: 0 10px;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 32px;
  }
}

.copyright__mobile {
  display: none;
}

.popup {
  overflow: hidden;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #201d4f;
  z-index: 999;
  top: 0;
  display: flex;
  flex-direction: column;
}

.ui-widget-overlay {
  background: #000 !important;
  opacity: 0.6 !important;
}

.ui-dialog-titlebar {
  padding: 0 !important;
  border: none !important;
}

.ui-dialog-title {
  display: none !important;
}

[role="dialog"] {
  padding: 0 !important;
}

video {
  width: 100%;
  height: 100%;
}

.ui-button {
  z-index: 99 !important;
  top: 0 !important;
  right: -10px !important;
}

#dialog {
  padding: 0 !important;
}

.dialogs {
  display: none;
}

.menu-button {
  padding: 30px;

  &__image {
    max-width: 15px;
  }
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.menu-item {
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  text-decoration: none;
  margin-top: 40px;
  color: #fff;

  &:first-child {
    margin-top: 50px;
  }

  &:last-child {
    margin-bottom: 70px;
  }
}

.menu-footer {
  justify-self: baseline;
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 70px;
}

// roadmap

.roadmap {
  overflow: hidden;
  background-image: url("../images/roadmap.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__title {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    padding-top: 45px;
    padding-left: 85px;
  }
}

.map-item__step--pipe-first-active,
.map-item__step--pipe-second-active,
.map-item__step--active {
  opacity: 0;
}

.map {
  margin-top: 50px;
  position: relative;

  &-item {
    position: absolute;

    &--active {
      & .map-item__step--pipe-first-active,
      & .map-item__step--pipe-second-active,
      & .map-item__step--active {
        opacity: 1;
      }
    }

    &--active:nth-child(5) {
      & .map-item__step--pipe-first-active,
      & .map-item__step--pipe-second-active,
      & .map-item__step--active {
        opacity: 1 !important;
      }
    }

    &--current {
      & .map-item__step--pipe-first-active,
      & .map-item__step--pipe-second-active {
        opacity: 0;
      }
    }

    &--current {
      & .map-item__step--man {
        opacity: 1;
      }
    }

    &__step {
      position: absolute;
      top: 0;
      left: 0;

      transition: 1s all;

      &--man {
        top: -62px;
        left: 60px;
        opacity: 0;
      }

      &--button {
        width: 200px;
        height: 120px;
        //background: #000;
        top: 195px;
        left: 100px;
        opacity: 0.5;
        cursor: pointer;
        z-index: 999;
      }
    }

    &--skeleton {
      opacity: 0.2;
    }

    &[data-id="1"] {
      top: 110px;
      left: -58px;

      & .map-item__step--pipe-first {
        top: 50px;
        left: 58px;
      }

      & .map-item__step--pipe-first-active {
        top: 50px;
        left: 58px;
      }

      & .map-item__step--pipe-second {
        left: 207px;
        top: 42px;
      }

      & .map-item__step--pipe-second-active {
        left: 207px;
        top: 42px;
      }
    }

    &[data-id="2"] {
      top: 71px;
      left: 173px;

      & .map-item__step--pipe-first {
        top: 81px;
        left: 210px;
      }

      & .map-item__step--pipe-first-active {
        top: 81px;
        left: 210px;
      }
    }

    &[data-id="3"] {
      top: 101px;
      left: 402px;

      & .map-item__step--pipe-first {
        top: 50px;
        left: 218px;
      }

      & .map-item__step--pipe-first-active {
        top: 50px;
        left: 218px;
      }
    }

    &[data-id="4"] {
      top: 65px;
      left: 634px;

      & .map-item__step--pipe-first {
        top: 92px;
        left: 212px;
      }

      & .map-item__step--pipe-first-active {
        top: 92px;
        left: 212px;
      }
    }

    &[data-id="5"] {
      top: 95px;
      left: 859px;

      & .map-item__step--pipe-first {
        top: 57px;
        left: 243px;
      }

      & .map-item__step--pipe-first-active {
        top: 57px;
        left: 243px;
      }
    }
  }
}

.menu-button {
  display: none;
}

.feature-card {
  display: flex;
  max-width: 540px;
  background: #353272;
  border-radius: 30px;
  margin-top: 30px;

  &__image {
    border-radius: 30px;
  }

  &__description {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  &__level {
    font-size: 14px;
    line-height: 17px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 5px;

    color: #e999c7;
  }

  &__text {
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;

    color: #837ed2;
  }

  &__button {
    &-text {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #ff67a2;
      margin-left: 15px;
    }

    &-container {
      margin-top: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-image {
      width: 100%;
    }

    &-image-container {
      max-width: 30px;
    }
  }
}

.roadmap-description {
  display: flex;
  margin-top: 575px;
  min-height: 340px;
  padding-left: 85px;

  &__image {
    display: none;
    &--step {
    }
  }

  &__container {
    display: none;
    width: 100%;
    flex-direction: column;

    &--active {
      display: flex;
    }
  }

  &__row-container {
    display: flex;
  }

  &__row {
    flex: 1;
    padding-right: 80px;
  }

  &__title {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }
}

.slider-button {
  position: absolute;
  z-index: 999;
  width: 5.2%;
  margin-left: -2%;

  &__image {
    max-width: 34px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    float: left;
    cursor: pointer;
  }

  &__text {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #1e1c4c;
    opacity: 0.5;
    margin-top: 10px;
  }
}

.for-what-image {
  margin-bottom: -7px;
}

.roadmap-description__container[data-id="0"] {
  display: none;
}

.mobile-roadmap-description {
  display: none;
}

// responsive

@media all and (max-width: 1199px) {
  body {
    background: #141236;
  }
  .menu-button {
    display: flex;

    &__close {
      display: none;
    }
  }

  .main-screen {
    background-image: url("../images/first-background-mobile.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .menu {
    display: none;
  }

  .logo__image {
    max-width: 180px;
  }

  .main-buildings-text {
    position: absolute;
    transform: rotate(0);
    z-index: 5;
    width: 100%;
    height: 150px;
    margin: 0;
    margin-bottom: 314em;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 275;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.28em;
    color: rgba(255, 255, 255, 0.14118);
    text-align: center;
    background: #15133d;
    box-shadow: 0 0 110px 85px #15133d;
    opacity: 1;
  }

  .conference_hall {
    position: relative;
    background: #15133d;
    z-index: 6;
    &__back {
      width: 405px;
      height: 476px;
      position: absolute;
      margin: 0;
      margin-left: -30px;
      background: url(../images/conference_hall/conference_back_mobile.png);
      background-size: cover;
      backdrop-filter: blur(140px);

      border-radius: 24.5221px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 30px -5 #ff4a8f76;

        border-radius: 24.5221px;
      }

      h1 {
        margin-left: 50px;
        margin-top: 10%;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 39px;
      }
      .top-of-front-text {
        position: absolute;
        margin-left: 50px;
        margin-top: 0%;
        font-weight: 300;
        font-size: 14px;
        line-height: 115%;
      }
      ul {
        margin-top: 5em;
        margin-left: 2%;
        li {
          list-style-type: none;
          font-size: 18px;
          background: url(../images/conference_hall/marker.png) no-repeat left
            center;
          margin-bottom: 21px;
          p {
            margin-left: 2em;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            &.mob {
              padding-top: 28px;
              margin-top: -25px;
            }
          }
        }
      }
    }
    &__front {
      position: relative;
      width: 335px;
      height: 154px;
      z-index: 999;
      margin: 0;

      top: 380px;
      left: 30px;

      background: url(../images/conference_hall/conference_hall_blur.png) 100%
        100% no-repeat;
      background-size: cover;
      transition: background-image 1s;
      &:hover {
        background: url(../images/conference_hall/conference_hall.png) 100% 100%
          no-repeat;
        background-size: cover;
      }

      border-radius: 20px;
    }
  }

  .colliseum {
    margin-top: 80%;
    height: auto;
    background: linear-gradient(180deg, #171541 0%, #1b1845 100%);
    h1 {
      width: 100%;
      font-weight: 300;
      font-size: 15px;
      line-height: 20px;
    }
    div {
      width: auto;
      margin: auto 5%;
      margin-top: 10%;
      h1 {
        &.pc {
          display: none;
        }
        &.mobile {
          display: block;
          margin-bottom: 7px;
        }
      }
      p {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        &.mobile {
          font-style: normal;
          font-weight: 100;
          font-size: 14px;
          line-height: 115%;
          margin-top: 0;
        }
      }
    }
    img {
      display: block;
      width: 100%;
    }
  }

  .hallverfull {
    background-size: auto;
    background-position-y: bottom;
  }

  .introduce {
    width: 95%;
    margin-top: 330px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: -95px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__subtitle {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      margin-left: 5px;
    }

    &__title {
      width: 90%;
      margin: 0 5px;
      margin-top: 5px;
      font-weight: 900;
      font-size: 22px;
      line-height: 27px;
    }

    &__button {
      margin-top: 30px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      z-index: 10;
    }
  }

  .metaverse {
    margin-top: 70px;
    margin-left: 25px;

    &__title {
      font-weight: 900;
      font-size: 22px;
      line-height: 27px;
    }
  }

  .metaverse-item {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
    img {
      max-height: 620px;
    }
    &__title {
      font-size: 20px;
      line-height: 24px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 22px;
      margin-top: 20px;
    }
  }

  .slick-dots {
    position: relative !important;
    margin-top: -110px !important;
    margin-bottom: 170px !important;
  }

  .slick-dots li {
    width: 30px !important;
    height: 8px !important;
    margin: 0 7px !important;
  }

  .slick-dots li button {
    height: 100% !important;
    width: 100% !important;
  }

  .slick-dots li button {
    background: #ffffff !important;
    opacity: 0.2 !important;
  }

  .slick-dots li button[aria-selected="true"] {
    background: #e999c7 !important;
    opacity: 1 !important;
  }

  .slick-dots li button:before {
    display: none !important;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    background: #e999c7 !important;
    opacity: 1 !important;
  }

  .features {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .features-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .features-description__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    margin-left: -100px;
  }

  .features-description__elements {
    width: 80%;
    margin-bottom: 35px;
  }

  .features-element__text {
    font-size: 16px;
    line-height: 20px;
  }

  .for-what {
    flex-direction: column;

    padding: 0px 25px;
  }

  .for-what-container__space {
    height: 50px;
  }

  .for-what-image {
    width: 100%;
  }

  .for-what-description__title {
    font-size: 22px;
    line-height: 27px;
  }

  .for-what-element__text {
    font-size: 15px;
  }

  .for-what__image {
    margin-top: 50px;
  }

  .app-container {
    padding: 0 15px;

    .features {
      width: 100%;
    }

    .features-description__elements {
      margin-left: 25px;
      margin-top: 43px;
    }

    & .features__slider {
      margin-top: 35px;
      width: 100%;
      display: flex;
      max-width: 300px;
      position: relative;
      margin: 0 70px !important;
    }

    & .features-description {
      margin: 0;
      align-items: none !important;
      &__title {
        margin-left: 10px !important;
      }
    }

    & .features-element {
      margin-left: 0;
      margin-top: 25px;
    }

    & .features-element__text {
      margin-left: 15px;
    }

    & .slick-slide img {
      width: 100%;
    }
  }

  .profit {
    padding: 50px 25px 0px 25px;
    flex-direction: column;
    margin-top: -1px;
  }

  .profit__title {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    margin-left: 0;
  }

  .profit-item__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    &.mobile_text_top {
      padding-top: 0;
    }
  }

  .profit-item {
    margin-top: 20px;
  }

  .profit__text {
    padding: 0;
  }

  .profit-item__number {
    font-size: 45px;
    line-height: 55px;
    width: 25px;
    &.mobile_num_top {
      margin-left: 6px;
    }
  }

  .profit__image-container {
    display: flex;
    padding-top: 45px;
  }

  .footer .logo__image {
    max-width: 255px;
  }

  .copyright {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }

  .copyright__mobile {
    display: block;
  }

  .metaverse__slider {
    margin-left: -25px;
  }

  .features-slider {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;

    &__pc {
      display: none;
    }
  }

  .features-description__elements {
    width: 82%;
  }

  .feature-card {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 35px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    .feature-card__description--under {
      padding-bottom: 0;
    }
  }
  .slider_top_mobile {
    img {
      width: 100%;
    }
    .features-slider__row {
      display: none;
      margin: 0;
      &--video {
        width: 100%;
        z-index: 0;
      }
      &--play {
        top: 20%;
        right: 21%;
      }
    }
  }
  .sale-button {
    margin-left: 0;
  }
  .map {
    display: none;
  }

  .roadmap-description {
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }

  .slick-current.slick-active div .roadmap-description__container,
  .slick-slide div .roadmap-description__container {
    .roadmap-description__image--man {
      opacity: 0;
      transition: opacity 1s;
    }
  }
  .slick-slide div .roadmap-description__container .roadmap-description__image {
    &--step {
      display: block !important;
    }
    &--step-active {
      display: none !important;
    }
  }
  .slick-current.slick-active
    div
    .roadmap-description__container
    .roadmap-description__image,
  .slick-active
    div
    .roadmap-description__container
    .roadmap-description__image {
    &--step {
      display: none !important;
    }
    &--step-active {
      display: block !important;
    }
  }
  .slick-active div .roadmap-description__container {
    .roadmap-description__image--man {
      opacity: 1;
    }
  }

  .roadmap-description__container {
    display: flex;
    .roadmap-description__title,
    .roadmap-description__row-container {
      display: none;
    }
    .roadmap-description__image--step,
    .roadmap-description__image--step-active {
      margin-left: -240px;
      margin-top: 50px;
      position: relative;
    }
    .roadmap-description__image--man {
      position: absolute;
    }
    &[data-id="0"] {
      display: block;
    }
    &[data-id="1"] {
      .roadmap-description__image {
        &--man {
          margin-left: -170px;
        }
        &--step,
        &--step-active {
          z-index: 9;
        }
      }
    }
    &[data-id="2"] {
      .roadmap-description__image {
        &--step {
          margin-top: 15px;
          margin-left: -230px;
          z-index: 8;
        }
        &--step-active {
          margin-top: 15px;
          margin-left: -190px;
          z-index: 8;
        }
        &--man {
          margin-left: -150px;
          margin-top: -35px;
        }
      }
    }
    &[data-id="3"] {
      .roadmap-description__image {
        &--step {
          margin-top: 47px;
          margin-left: -205px;
          z-index: 7;
        }
        &--step-active {
          margin-left: -170px;
          margin-top: 48px;
          z-index: 7;
        }
        &--man {
          margin-left: -128px;
        }
      }
    }
    &[data-id="4"] {
      .roadmap-description__image {
        &--step {
          margin-top: 1px;
          margin-left: -198px;
          z-index: 6;
        }
        &--step-active {
          margin-left: -163px;
          margin-top: 2px;
          z-index: 6;
        }
        &--man {
          margin-left: -120px;
          margin-top: -47px;
        }
      }
    }
    &[data-id="5"] {
      .roadmap-description__image {
        &--step {
          margin-top: 30px;
          margin-left: -179px;
          z-index: 5;
        }
        &--step-active {
          margin-left: -143px;
          margin-top: 27px;
          z-index: 5;
        }
        &--man {
          margin-left: -105px;
          margin-top: -25px;
        }
      }
    }
  }

  .mobile-roadmap-description {
    display: block;
    height: 500px;
    .roadmap-description__title[data-id="1"] {
      margin-top: -50px;
    }
    .roadmap-description__title[data-id="2"] {
      margin-top: -400px;
    }
    .roadmap-description__title[data-id="3"] {
      margin-top: -190px;
    }
    .roadmap-description__title[data-id="4"] {
      margin-top: -270px;
    }
    .roadmap-description__title[data-id="5"] {
      margin-top: -310px;
    }
    .roadmap-description__title,
    .roadmap-description__row-container {
      visibility: hidden;
      opacity: 0;
      padding: 0 20px;
      transition: all 1s;
      &--active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .slick-list,
  .slick-track {
    touch-action: pan-y;
  }

  .roadmap-description__row-container {
    flex-direction: column;
  }

  .roadmap-description__image {
    margin: 0 auto;
  }

  .roadmap__title {
    padding-left: 20px;
  }

  .roadmap-description__row {
    padding: 0;
  }
  .roadmap-description {
    & .slick-prev,
    & .slick-next,
    & .slick-prev:focus,
    & .slick-next:focus,
    & .slick-prev:hover,
    & .slick-next:hover {
      background-image: url("../images/prev.svg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
      width: 32px;
      height: 32px;
      z-index: 999;
      top: 200px;

      &:before {
        display: none;
      }
    }

    & .slick-prev {
      left: 30px;
    }

    & .slick-next {
      right: 30px;
      background-image: url("../images/next.svg") !important;
    }
    .slick-disabled {
      display: none !important;
    }
  }

  .features-slider__pc {
    display: none !important;
  }
  .slider-button {
    display: none;
  }

  .metaverse__title {
    margin-left: 0;
  }

  .profit__container {
    padding-left: 0;
    flex-direction: column;
  }

  .profit__image {
    max-width: 380px;
  }

  .faq {
    flex-direction: column;
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 39px;
      width: 100%;
    }
  }
  .faq-slider {
    width: 100% !important;
  }
  .faq-answer {
    margin-left: 50px !important;
  }

  .roadmap {
    .slick-dots {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.mobile-roadmap-description {
  display: none;
}

@media (max-width: 1199px) {
  .mobile-roadmap-description {
    display: block;
  }
  .roadmap-description {
    //display: none;
  }
}
