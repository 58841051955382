.dashboard {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 45px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;

  &__column {
    display: flex;
    flex-direction: column;
  }
}

.back-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;

  &__image {
    width: 12px;
    height: 12px;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    margin-left: 7px;
  }
}

.dashboard-details {
  margin-top: 10px;
  display: flex;
  align-items: center;


  &__name {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
  }

  &__status {
    margin-left: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #C3F438;
  }
}

.dashboard-header-info {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.85);

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  &__address {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}

.app-details {
  margin-top: 40px;
  background: #2F2C58;
  border-radius: 60px;
  padding: 26px 37px;
  display: flex;
  justify-content: space-between;

  &__title {
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    color: #E999C7;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }

  &__key-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #7772BC;
  }

  &__key {
    margin-left: 12px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}


.nfts {
  margin-top: 50px;
  display: flex;

  &__column {
    flex: 1;
  }

  &__row-container {
    display: flex;
    margin-top: 35px;
  }

  &__title {
    display: flex;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #7772BC;
  }
}

.nft-card {
  max-width: 240px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  margin-left: 30px;

  &--long {
    max-width: none;
    padding: 35px 100px 60px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__logo {
    background: #fff;
    padding: 5px;
    border-radius: 60%;
    max-width: 64px;
  }

  &__title {
    margin-top: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #C3F438;
  }

  &__overview {
    margin-right: 6px;
    margin-left: 6px;

    &--to-right {
      text-align: right;
    }

    &-container {
      margin-top: 35px;
      display: flex;
    }

    &-title {
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
      margin-left: 5px;
      margin-right: 5px;
    }

    &-sum {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #FF67A2;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }

    &-button {
      margin-top: 25px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
      padding: 7px 30px;
      background: #FF66A1;
      border-radius: 31px;
      text-align: center;

      &--transparent {
        padding: 5px 20px;
        background: transparent;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 31px;
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &__level {
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    padding: 0 16px;
    margin-top: 10px;
  }

  &__description {
    margin-top: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 0 16px;
    padding-bottom: 20px;
  }
}
