.features {
  display: flex;
  margin: 0 auto;
  margin-top: 5%;

  .slick-dots {
    position: relative !important;
  }

  .slick-dots li {
    width: 30px !important;
    height: 8px !important;
    margin: 0 7px !important;
  }

  .slick-dots li button {
    height: 100% !important;
    width: 100% !important;
  }

  .slick-dots li button {
    background: #FFFFFF !important;
    opacity: 0.2 !important;
  }

  .slick-dots li button[aria-selected="true"] {
    background: #E999C7 !important;
    opacity: 1 !important;
  }

  .slick-dots li button:before {
    display: none !important;
  }

  .slick-dots li button:hover, .slick-dots li button:focus {
    background: #E999C7 !important;
    opacity: 1 !important;
  }
}

.feature-slide-button {
  margin: 0 10px;
  width: 46px;
  height: 12px;
  background: #fff;
  opacity: 0.2;

  &--active {
    background: #e999c7;
    opacity: 1;
  }
}

.features-slide {
  position: relative;

  &__overflow {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(23, 21, 65);
    background: linear-gradient(180deg, rgba(23, 21, 65, 0) 0%, rgba(23, 21, 65, 0.3491771708683473) 70%, rgba(23, 21, 65, 0.7049194677871149) 85%, rgba(36, 33, 86, 1) 100%);
    border-radius: 31px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    opacity: 0;

    transition: 0.3s all;

    &:hover {
     opacity: 1;
    }
  }

  &__level {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #E999C7;
  }
}

.features-slider {
  display: flex;
  flex-direction: column;
  max-width: 640px;


  .slick-next{
    position: absolute;
    width: 32%;
    height: 50px;
    top: 88%;
    left: 52%;
    z-index: 10;
    opacity: 0;
  }

  .slick-prev{
    position: static;
    cursor: default;
    opacity: 0;
  }

  &__mobile {
    display: none;
  }

  &__cards{
    display: flex;
    .features-slide__second{
      margin-left: 30px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 50px;
    margin-top: 30px;
    &--video{
      width: 86%;
      border-radius: 31px;
      cursor: default;
      z-index: 10;
    }
    &--play{
      position: absolute;
      width: 147px;
      height: 147px;
      top: 39%;
      right: 16%;
      background: url(../images/feature-slider/play.png);
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
    }

    &:first-child {
      margin-top: 0;
    }

    &--center {
      margin-top: 40px;
      justify-content: center;
    }
  }
}

.features-description {
  margin-left: 85px;

  &__title {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    margin-top: 10%;
  }

  &__elements {
    display: flex;
    flex-direction: column;
  }
}

.features-element {
  margin-top: 10%;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;

  &__text {
    margin-left: 32px;
    max-width: 310px;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
  }
}

.sale-button {
  background: #ff66a1;
  box-shadow: 0px 0px 17px rgba(255, 102, 161, 0.85);
  border-radius: 16px;
  border: none;
  margin-left: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 80px;
  margin-top: 40px;
  cursor: pointer;
  &:hover{
    background: #FF498E;
    box-shadow: 0px 0px 25px rgba(255, 102, 161, 0.4);
  }

  &__text {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-left: 5px;
    color: #fff;
  }
}
