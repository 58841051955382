.for-what {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 50px;
  padding-bottom: 0;

  &-container {
    background: url("../images/for-what-background.png") no-repeat;
    background-size: cover;

    &__space {
      background: #15133d;
      background: linear-gradient(180deg, #15133d 0%, rgba(0, 0, 0, 0%) 100%);
      width: 100%;
      height: 140px;
    }
  }
}

.for-what-description {
  &__title {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
  }

  &__elements {
    margin-top: 35px;
  }
}

.for-what-element {
  &__text {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    padding-left: 37px;
    margin-top: 20px;

    &::before {
      width: 10px;
      height: 10px;
      position: absolute;
      background: #ff67a2;
      top: 4px;
      left: 15px;
      content: "";
      border-radius: 50%;
    }
  }
}
