.metaverse {
  margin-bottom: 15%;

  &__title {
    margin-top: 10%;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    margin-left: 192px;
  }

  &__slider {
    display: block;
    margin-bottom: -165px;
  }
}

.metaverse-item {
  min-height: 420px;
  max-width: 15vw;
  min-width: 15vw;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px 4.5vw;
  padding-bottom: 50px;
  border-radius: 25px;
  
  &--first {
    margin: 0px 17.5vw;
    margin-left: 40%;
  }

  &__image {
    max-height: 40vw;
  }

  &__title {
    position: absolute;
    top: 57%;
    font-weight: 800;
    font-size: 1.5vw;
    line-height: 2vw;
    text-align: center;
  }

  &__subtitle {
    position: absolute;
    top: 64%;
    font-size: 1vw;
    line-height: 1.5vw;
    text-align: center;
    margin-top: 2vw;
    padding: 0 20px;
  }
}
