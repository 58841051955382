.profit {
  width: 100%;
  display: flex;
  background: #e999c7;
  padding: 50px;
  padding-bottom: 0;
  justify-content: center;

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding-left: 50px;
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__text {
    padding-top: 55px;
    max-width: 450px;
  }

  &__title {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    color: #1E1C4C;
    margin-left: -25px;
  }
}

.profit-item {
  display: flex;
  margin-top: 35px;

  &__number {
    font-weight: 900;
    font-size: 45px;
    line-height: 55px;
    text-align: center;
  }

  &__text {
    font-family: 'Nunito', sans-serif;
    margin-left: 25px;
    padding-top: 8px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: #1E1C4C;
  }
}
